import React from 'react';
import {
  DataGrid,
  Column,
  Editing,
  Lookup,
} from 'devextreme-react/data-grid';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import SelectBox from 'devextreme-react/select-box';
import { CreateAuthorizedDataSource, SetAuthorizationHeaderAsync, CreateAuthorizedStore } from '../../helpers/data';
import { appConst } from '../../AppConst';

import calcGridHeight,
{ setDateInlineEditing } from '../../helpers/ui';

const committeeMembershipUrl = `${window.env.apiEndpoint}/api/v1/CommitteeMemberships`;

const committeesData = CreateAuthorizedDataSource(
  { loadUrl: `${committeeMembershipUrl}/CommitteesLookup` },
  null,
  'Value',
  null,
  'Header',
);
const schoolYearsData = CreateAuthorizedDataSource(
  { loadUrl: `${committeeMembershipUrl}/SchoolYearsLookup` },
  null,
  'Value', false,
);

const getFilteredCommitteeRoleData = (options) => ({
  store: CreateAuthorizedStore(
    { loadUrl: `${committeeMembershipUrl}/GetRoleLookups` },
    null,
    'Value', false,
  ),
  filter: options.data ? ['CommitteeId', '=', options.data.CommitteeId] : null,
});

class CommitteeGrid extends React.Component {
  constructor(props) {
    super(props);
    this.selectBoxCommitteeColumnTemplate = this.selectBoxCommitteeColumnTemplate.bind(this);
    this.selectBoxSchoolYearColumnTemplate = this.selectBoxSchoolYearColumnTemplate.bind(this);
    this.committeeMembershipDataSourceStore = this.committeeMembershipDataSourceStore.bind(this);
  }

  committeeMembershipDataSourceStore() {
    return createStore(
      {
        insertUrl: `${committeeMembershipUrl}/AddPersonToCommittee`,
        loadUrl: `${committeeMembershipUrl}/GetAssociatedCommitteesByPerson`,
        deleteUrl: `${committeeMembershipUrl}/Delete`,
        updateUrl: `${committeeMembershipUrl}/UpdateCommitteeMembership`,
        key: 'MembershipId',
        onBeforeSend: async (method, ajaxOptions) => {
          await SetAuthorizationHeaderAsync(ajaxOptions);
          const { personId } = this.props;
          if (method === 'insert') {
            const {
              committeeId,
              schoolYearId,
              startDate,
              endDate,
              roleText,
            } = this.state;
            ajaxOptions.data.personId = personId;
            ajaxOptions.data.committeeId = committeeId;
            ajaxOptions.data.schoolYearId = schoolYearId;
            ajaxOptions.data.startDate = startDate;
            ajaxOptions.data.endDate = endDate;
            ajaxOptions.data.roleText = roleText;
          }
          if (method === 'load') {
            ajaxOptions.data.personId = personId;
          }
        },
      },
    );
  }

  setGridHeight() {
    this.committeeMembershipsGrid.instance.option('height', calcGridHeight('committeeMembershipGrid'));
  }

  onEditorPreparingCommitteeMembershipsGrid(e) {
    setDateInlineEditing(['CommitteeMembershipStartDate', 'CommitteeMembershipEndDate'], e);
    if (e.dataField === "CommitteeRoleId" && e.row.rowType === "data") {
      if (e.row.isNewRow) {
        e.editorOptions.disabled = false;
      } else {
        e.editorOptions.disabled = true;
      }
    }
  }  

  onSelectBoxCommitteeValueChanged(cell, e) {
    cell.setValue(e.value);
    this.setState({
      committeeId: e.value,
    });
    const i = cell.row.cells.find((c) => c.column.dataField === 'CommitteeRoleId').columnIndex;
    cell.row.cells[i].setValue(null);
  }

  selectBoxCommitteeColumnTemplate(cell) {
    const onValueChanged = this.onSelectBoxCommitteeValueChanged.bind(this, cell);
    return (
      <SelectBox
        {...appConst.defaultSelectBoxOptions}
        defaultValue={cell.value}
        {...cell.column.lookup}
        onValueChanged={onValueChanged}
        itemRender={this.itemRender}
        grouped
        dataSource={committeesData}
        disabled={typeof cell.row.isNewRow === 'undefined'}
      />
    );
  }

  onSelectBoxSchoolYearValueChanged(cell, e) {
    cell.setValue(e.value);
    this.setState({
      schoolYearId: e.value,
    });
    const items = e.component.getDataSource().items();
    const item = items.find((item) => item.Value === e.value);
    if (item) {
      const i1 = cell.row.cells.find((c) => c.column.dataField === 'CommitteeMembershipStartDate').columnIndex;
      const i2 = cell.row.cells.find((c) => c.column.dataField === 'CommitteeMembershipEndDate').columnIndex;

      cell.row.cells[i1].setValue(item.StartDate);
      cell.row.cells[i2].setValue(item.EndDate);
    }
  }

  selectBoxSchoolYearColumnTemplate(cell) {
    const onValueChanged = this.onSelectBoxSchoolYearValueChanged.bind(this, cell);
    return (
      <SelectBox
        {...appConst.defaultSelectBoxOptions}
        defaultValue={cell.value}
        {...cell.column.lookup}
        onValueChanged={onValueChanged}
        itemRender={this.itemRender}
        grouped={false}
        dataSource={schoolYearsData}
        disabled={typeof cell.row.isNewRow === 'undefined'}
      />
    );
  }

  render() {
    return (
      <DataGrid
        id="committeeMembershipGrid"
        ref={(ref) => { this.committeeMembershipsGrid = ref; }}
        dataSource={this.committeeMembershipDataSourceStore()}
        {...appConst.defaultGridOptions}
        onRowInserting={
          (e) => {
            this.setState({
              committeeId: e.data.CommitteeId,
              schoolYearId: e.data.SchoolYearId,
              startDate: e.data.CommitteeMembershipStartDate,
              endDate: e.data.CommitteeMembershipEndDate,
              roleId: e.data.CommitteeRoleId,
            });
          }
        }
        onEditorPreparing={this.onEditorPreparingCommitteeMembershipsGrid}
      >
        <Editing
          mode="row"
          allowAdding
          allowDeleting
          allowUpdating
        />
        <Column
          dataField="CommitteeId"
          dataType="number"
          caption="Committee"
          editCellRender={this.selectBoxCommitteeColumnTemplate}
        >
          <Lookup
            dataSource={committeesData.store()}
            valueExpr="Value"
            displayExpr="Text"
          />
        </Column>
        <Column
          dataField="CommitteeRoleId"
          dataType="number"
          caption="Role"
          editorOptions={{
            onFocusOut: (e) => {
                this.setState({ roleText: e.component.option('text') });
            }}
          }
        >
          <Lookup
            dataSource={getFilteredCommitteeRoleData}
            displayExpr="Text"
            valueExpr="Value"
          />
        </Column>
        <Column
          dataField="SchoolYearId"
          dataType="number"
          caption="School Year"
          editCellRender={this.selectBoxSchoolYearColumnTemplate}
        >
          <Lookup
            dataSource={schoolYearsData.store()}
            valueExpr="Value"
            displayExpr="Text"
          />
        </Column>
        <Column
          dataField="CommitteeMembershipStartDate"
          dataType="date"
          caption="Start Date"
          format={appConst.dateDisplayFormat}
        />
        <Column
          dataField="CommitteeMembershipEndDate"
          dataType="date"
          caption="End Date"
          format={appConst.dateDisplayFormat}
        />
      </DataGrid>
    );
  }
}
export default React.memo(CommitteeGrid);
