import React from 'react';
import Form, { SimpleItem, Label, GroupItem, RequiredRule } from 'devextreme-react/form';
import DxForm from "devextreme/ui/form";
import { Popup } from 'devextreme-react/popup';
import notify from 'devextreme/ui/notify';
import moment from 'moment';
import { appConst } from '../../AppConst';
import { CreateAuthorizedStore, CreateAuthorizedDataSource } from '../../helpers/data';
import { setFocusOnFirstInput } from '../../helpers/ui';
import { NameAutoComplete } from '../../components';
import './AddMemberPopup.scss';

const URL = `${window.env.apiEndpoint}/api/v1/CommitteeMemberships`;

class AddMemberPopup extends React.Component {
  constructor(props) {
    super(props);
    const { selectedStartDate, selectedEndDate } = this.props;
    this.state = {
      personId: 0,
      selectedCommitteeId: 0,
      selectedStartDate,
      selectedEndDate,
      roleId: 0,
      roleText: null,
    };

    this.startDateSelectBoxValueChanged = this.startDateSelectBoxValueChanged.bind(this);
    this.endDateSelectBoxValueChanged = this.endDateSelectBoxValueChanged.bind(this);
    this.setPersonId = this.setPersonId.bind(this);
    this.roleLookupsStore = CreateAuthorizedDataSource(
      { loadUrl: `${window.env.apiEndpoint}/api/v1/CommitteeMemberships/GetRoleLookupsForCommittee` },
      { load: { committeeId: this.props.selectedCommitteeId }, }, 'Value');
  }

  async componentDidMount() {
    let data = await this.roleLookupsStore.store().load();
    this.setState({ roleLookupData: data });
  }

  addMemberShipButtonOptions = {
    icon: 'save',
    text: 'Save',
    width: '100%',
    onClick: () => {
      let formElement = document.getElementById('addCommitteeMembershipForm');
      let form = DxForm.getInstance(formElement);
      if (!form.validate().isValid) return;

      const addMemberStore = CreateAuthorizedStore(
        { insertUrl: `${URL}/AddPersonToCommittee` },
        {
          insert: {
            personId: this.state.personId,
            committeeId: this.props.selectedCommitteeId,
            schoolYearId: this.props.selectedSchoolYearId,
            startDate: this.state.selectedStartDate,
            endDate: this.state.selectedEndDate,
            roleId: this.state.roleId,
            roleText: this.state.roleText,
          },
        }, 'Id',
      );

      addMemberStore.insert().then(
        () => {
          const { hideAddMemberPopup, membersGridRef } = this.props;
          membersGridRef.refresh();
          this.setState({
            personId: 0,
            selectedCommitteeId: 0,
          },
            () => { hideAddMemberPopup(); });
        },
        (error) => { notify({ message: error.message, shading: true }, 'error'); },
      );
    },
  }

  backMemberShipButtonOptions = {
    icon: 'revert',
    text: 'Back',
    width: '100%',
    onClick: () => {
      const { hideAddMemberPopup } = this.props;
      this.setState({
        personId: 0,
        selectedCommitteeId: 0,
      },
        () => { hideAddMemberPopup(); });
    },
  }

  startDateSelectBoxValueChanged(e) {
    this.setState({
      selectedStartDate: e.value,
    });
  }

  endDateSelectBoxValueChanged(e) {
    this.setState({
      selectedEndDate: e.value,
    });
  }

  personInfo(item) {
    return (
      <div className="person-info-autocomplete-container">
        <div>{item.FullName}</div>
        <div className="push">{item.Type}</div>
      </div>
    );
  }

  setPersonId(personId) {
    this.setState({ personId: personId })
  }

  render() {
    return (
      this.props.showAddMemberPopup
      && (
        <Popup
          showTitle
          title="Add Person to Committee"
          visible={this.props.showAddMemberPopup}
          resizeEnabled
          height="auto"
          onShown={(e) => {
            setFocusOnFirstInput(e.component.content().parentNode);
          }}
          dragEnabled
          onHidden={(e) => { this.backMemberShipButtonOptions.onClick(); }}
          minHeight={300}
        >
          <Form
            id="addCommitteeMembershipForm"
            scrollingEnabled
          >
            <GroupItem colCount={2}>
              <SimpleItem
                dataField="FullName"
                colSpan={2}
              >
                <Label text="Select Member" />
                <NameAutoComplete
                  dataSource={this.props.membersLookupData}
                  itemRender={this.personInfo}
                  setId={this.setPersonId}
                />
              </SimpleItem>
              <SimpleItem
                editorType="dxSelectBox"
                dataField="RoleId"
                colSpan={2}
                editorOptions={{
                  dataSource: this.state.roleLookupData,
                  ...appConst.defaultSelectBoxOptions,
                  grouped: false,
                  acceptCustomValue: true,
                  onValueChanged: (e) => {
                    const selectedItem = e.component.option('selectedItem');
                    if (selectedItem) {
                      this.setState({ roleId: selectedItem.Value, roleText: selectedItem.Text });
                    } else {
                      this.setState({ roleId: 0, roleText: null });
                    }
                  },
                  onCustomItemCreating: (e) => {
                    if (!e.text) {
                      e.customItem = null;
                      return;
                    } else {
                      let maxValue = Math.max(...this.state.roleLookupData.map(v => v.Value));
                      e.customItem = { Value: maxValue + 1, Text: e.text };
                      this.setState({ roleLookupData: [...this.state.roleLookupData, e.customItem] })
                    }
                  }
                }
              }
              >
                <RequiredRule message="Committee role is required" />
                <Label text="Role"/>
              </SimpleItem>
              <SimpleItem
                editorType="dxDateBox"
                editorOptions={
                  {
                    ...appConst.defaultSelectDateBoxOptions,
                    onValueChanged: (e) => {
                      this.setState({
                        selectedStartDate: moment(e.value).format('YYYY/MM/DD'),
                      });
                    },
                    onContentReady: (e) => {
                      e.component.option('value', this.state.selectedStartDate);
                    },
                  }
                }
              >
                <Label text="Start Date" />
              </SimpleItem>
              <SimpleItem
                editorType="dxDateBox"
                editorOptions={
                  {
                    ...appConst.defaultSelectDateBoxOptions,
                    onValueChanged: (e) => {
                      this.setState({
                        selectedEndDate: moment(e.value).format('YYYY/MM/DD'),
                      });
                    },
                    onContentReady: (e) => {
                      e.component.option('value', this.state.selectedEndDate);
                    },
                  }
                }
              >
                <Label text="End Date" />
              </SimpleItem>
              <SimpleItem
                itemType="button"
                horizontalAlignment="center"
                buttonOptions={this.addMemberShipButtonOptions}
              />
              <SimpleItem
                itemType="button"
                horizontalAlignment="center"
                buttonOptions={this.backMemberShipButtonOptions}
              />
            </GroupItem>
          </Form>
        </Popup>
      )
    );
  }
}

export default AddMemberPopup;
